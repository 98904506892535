*{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App{
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background: linear-gradient(45deg, white, pink);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 45px;
}

#secondPage{
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background: linear-gradient(45deg, white, rgb(2, 213, 97));
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 45px;
}

.thirdPage {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background: linear-gradient(45deg, rgb(203, 138, 10), rgb(255, 54, 54));
  background-size: 200% 200%; /* Ensures a smooth wave effect */
  animation: wave 3s infinite alternate; /* 4-second animation that alternates */
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 45px;
}

/* Keyframes for the wave effect */
@keyframes wave {
  0% {
    background-position: bottom left;
  }
  100% {
    background-position: top right;
  }
}


.title{
  font-size: 32px;
  font-family: fantasy;
  border-bottom: 3px dotted red;
  padding: 15px;
}

.p-container{
  padding-left: 10%;
  padding-right: 10%;
  font-size: 22px;
  font-family: coax;
  line-height: 24px;
  cursor: pointer;
  transition: filter 1s ease;
}

.myBtn {
  border: none;
  padding: 10px 20px;
  background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  color: white;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Pacifico', cursive; /* A playful, fancy font */
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 15px rgba(255, 105, 180, 0.4);
}

.myBtn:hover {
  background: linear-gradient(135deg, #ff758c, #ff7eb3);
  transform: scale(1.1);
  box-shadow: 0 8px 20px rgba(255, 105, 180, 0.6);
}

.myBtn:active {
  transform: scale(0.95);
}


.input_box{
  position: relative;
  font-size: 30px;
  height: 30px;
  width: 80%;
  margin: 50px;
  padding: 3px;
  font-family: coax;
  border-radius: 10px;
  border: 2px solid palevioletred;
  box-shadow: 5px 5px 5px grey;
}

.myBtn{
  background: transparent;
  padding: 10px;
}

/* Game.css */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: opacity 1s ease;
}

h1 {
  font-size: 24px;
  text-align: center;
}

.win{
  font-size: 30px;
  padding: 50px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  grid-gap: 20px;
  justify-content: center;
  z-index: 2;
}

.image-container {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.text-item {
  cursor: pointer;
  font-size: 18px;
  color: #333;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.text-item:hover {
  color: #f00; /* Highlight text on hover */
}

#abs-div{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 600px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.abs-img{
  max-width: 100%;
  max-height: 100%;

}

.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 40px;
    border-radius: 100px;
    transition: all .2s;
    display: inline-block;
    margin: 5px;
    overflow-y: hidden;
}

.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: #fff;
    color: #777;
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.btn-white::after {
    background-color: #fff;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.btn-animated {
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
}

.answer-button{
  font-size: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: violet;
  color: white;
  border-color: black;
}

.game-grid{
  position: relative;
  align-self:center;
  width: 150vmin;
  height: 80vmin;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 10px;
  gap: 30px;
  padding: 10px;
}

.card{
  background-color: #61dafb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s, background 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.myInput {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 18px;
  border: 2px solid #ffb6c1; /* Light pink border */
  border-radius: 10px; /* Rounded corners */
  outline: none;
  background-color: #fff0f5; /* Soft pink background */
  color: #ff69b4; /* Hot pink text */
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.myInput:focus {
  border-color: #ff69b4; /* Hot pink border on focus */
  box-shadow: 0 0 10px #ffb6c1; /* Soft glow */
}

#animated{
  overflow: hidden;
  text-wrap: nowrap;
  animation: typing 3s steps(30) infinite alternate-reverse;
  width: 40ch;
}

@keyframes typing {
  from{
    width: 0ch;
  }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}