.game-button{
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: rgb(255, 86, 114);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.game-p{
	font-size: 28px;
}